import React, { useState } from "react";
import { HiArrowRight } from "react-icons/hi";
import { useForm } from "react-hook-form";
// import GoogleLogin from "react-google-login";

import BiconomyWordmark from "assets/branding/biconomy-wordmark.svg";
// import { gapi } from "gapi-script";

import TextInput from "components/inputs/TextInput";
import PrimaryButton from "components/buttons/PrimaryButton";
// import GithubButton from "components/buttons/social/GithubButton";
// import GoogleButton from "components/buttons/social/GoogleButton";
import { useAuth } from "context/Auth";
import { Link } from "react-router-dom";
import Alert from "components/Alert";

// const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// const githubClientId = process.env.REACT_APP_GITHUB_LOGIN_CLIENT_ID;
// const githubClientSecret = process.env.REACT_APP_GITHUB_LOGIN_CLIENT_SECRET;
// const redirect_uri = "http://localhost:3000/auth/signin";

interface ISignInProps {}

const SignIn: React.FunctionComponent<ISignInProps> = (props) => {
  // const { state: { from = "/" } = {} } = useLocation();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const [githubData, setGithubData] = useState("");
  const { login } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   gapi.load("client:auth2", () => {
  //     gapi.auth2.init({
  //       client_id: googleClientId,
  //       scope: "",
  //     });
  //   });
  // }, []);

  // useEffect(() => {
  //   // After requesting Github access, Github redirects back to your app with a code parameter
  //   const url = window.location.href;
  //   const hasCode = url.includes("?code=");

  //   // If Github API returns the code parameter
  //   if (hasCode) {
  //     const newUrl = url.split("?code=");
  //     window.history.pushState({}, "", newUrl[0]);
  //     setGithubData("");

  //     const requestData = {
  //       code: newUrl[1],
  //     };

  //     githubLogin(requestData);
  //   }
  // }, [githubData, githubLogin]);

  // const handleFailure = (result: any) => {
  //   console.log(result);
  //   setError(result.error);
  // };

  return (
    <main className="flex flex-grow justify-center">
      <form className="flex flex-col max-w-sm flex-grow mx-3 pt-36 gap-5">
        <img src={BiconomyWordmark} className="w-[50%] mx-auto mb-14" alt="" />
        <div className="flex justify-between items-baseline">
          <h1 className="font-semibold text-lg">Sign In</h1>
          {/* <span className="text-sm text-foreground-light font-normal flex gap-1">
            New user?
            <Link to="../signup">
              <span className="text-biconomy-orange">Sign up now</span>
            </Link>
          </span> */}
        </div>
        <TextInput
          placeholder="Email"
          className="w-full"
          type="email"
          registry={register("email", {
            required: "Please enter your email address",
            pattern: {
              value:
                // eslint-disable-next-line no-useless-escape
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Invalid email address",
            },
          })}
        />
        <div className="flex gap-5">
          <TextInput
            placeholder="Password"
            className="flex-grow"
            type="password"
            registry={register("password", { required: true })}
          />
          <PrimaryButton
            className="w-[96px]"
            onClick={handleSubmit(async (data) => {
              setError("");
              if (errors.email || errors.password) return;
              if (data.password.length < 6) {
                setError("Incorrect Email ID/Password");
                return;
              }
              try {
                const res = await login(data.email, data.password);
                console.info(res);
                setSuccess("Successfully logged in, redirecting...");
              } catch (err: any) {
                console.error("signIn error", err);
                setError(err.response.data.error || err.message);
              }
            })}
            type="submit"
          >
            <HiArrowRight className="w-5 h-auto" />
          </PrimaryButton>
        </div>
        {errors.email && <Alert color="error" message={errors.email.message} />}
        {errors.password && <Alert color="error" message="Password is empty" />}
        {error && <Alert color="error" message={error} />}
        {success && <Alert color="success" message={success} />}
        <Link to="../reset-password">
          <span className="text-sm font-normal text-biconomy-orange pl-4">
            Forgot Password?
          </span>
        </Link>
        {/* <div className="flex gap-3 items-center py-2">
          <div className="flex-grow h-0 border border-light-border"></div>
          <span className="text-foreground-dark">Or</span>
          <div className="flex-grow h-0 border border-light-border"></div>
        </div> */}
        {/* <GoogleLogin
          render={(renderProps) => (
            <GoogleButton onClick={renderProps.onClick}>
              <span className="py-1.5 mx-auto">Sign in with Google</span>
            </GoogleButton>
          )}
          clientId={googleClientId || ""}
          onSuccess={gooogleLogin}
          onFailure={handleFailure}
          cookiePolicy={"single_host_origin"}
        ></GoogleLogin> */}

        {/* <GithubButton
          href={`https://github.com/login/oauth/authorize?scope=user&client_id=${githubClientId}&redirect_uri=${redirect_uri}`}
        >
          <span className="py-1.5 mx-auto">Sign in with Github</span>
        </GithubButton> */}
      </form>
    </main>
  );
};

export default SignIn;
