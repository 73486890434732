import { getNetworkDetail } from "utils/allSupportedNetwork";

export const getIcon = (networkId: string) => {
  let logo = getNetworkDetail(networkId, "currency");
  if (networkId === "5001" || networkId === "5000" || networkId === "11155111") logo = "ETH";
  if (networkId === "421611" || networkId === "42161") logo = "ARB";
  if (networkId === "10" || networkId === "420") logo = "OP";
  if (networkId === "1442" || networkId === "1101") logo = "MATIC"
  if (networkId === "82" || networkId === "83") logo = "MTR";
  return `/icons/networks/${logo}.svg`;
};
