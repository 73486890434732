export const getExplorerEndpoint = (network: string) => {
  switch (network) {
    case "1":
      return "https://api.etherscan.io";
    case "3":
      return "https://api-ropsten.etherscan.io";
    case "4":
      return "https://api-rinkeby.etherscan.io";
    case "42":
      return "https://api-kovan.etherscan.io";
    case "5":
      return "https://api-goerli.etherscan.io";
    case "137":
      return "https://api.polygonscan.com";
    case "80001":
      return "https://api.mumbai.polygonscan.com";
    case "80002":
      return "https://api.amoy.polygonscan.com";
    default:
      return "https://api.blockscan.com";
  }
};
